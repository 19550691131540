<template>
  <v-row class="mx-auto">
    <v-col
      class="pb-0"
      cols="12"
    >
      <v-data-table
        id="first-step-expurgo-table"
        v-model="selectedItemLocal"
        :headers="headers"
        :items="items"
        item-key="bdgd_versao_id"
        :single-select="true"
        show-select
        :loading="loading"
        loading-text="Carregando novos dados"
        :hide-default-header="loading"
        :sort-by="['empresa', 'data_registro', 'versao']"
        :sort-desc="['data_registro', 'versao']"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        no-results-text="Nenhum registro correspondente encontrado"
      >
        <template v-slot:header.data-table-select>
          <v-icon
            color="primary"
            @click="loadTable"
            >mdi-refresh</v-icon
          >
        </template>
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }}
        </template>
        <template v-slot:[`item.registros`]="{ item }">
          {{ item.registros | parseNumberToIntegerBR }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ExpurgosService from '@/services/ExpurgosService';

export default {
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [],
    headers: [
      {
        text: 'Empresa',
        value: 'empresa'
      },
      {
        text: 'Mês',
        value: 'data_registro'
      },
      {
        text: 'Versão',
        value: 'versao'
      },
      {
        text: 'Nº de Linhas',
        value: 'registros'
      }
    ]
  }),

  computed: {
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    }
  },

  watch: {
    itemsTable() {
      this.items = this.itemsTable.map((item) => {
        item.key =
          item.empresa_id + '-' + item.data_registro + '-' + item.versao;
        return item;
      });
    },
    selectedItem(arrNewVal) {
      this.$emit('stepReleased', arrNewVal.length);
    }
  },

  methods: {
    loadTable() {
      this.items = [];

      ExpurgosService.getDatesAndRegistersImportedEntities()
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.error(
            'Não foi possivel recuperar a lista de expurgos: ',
            error
          );
        });
    }
  }
};
</script>
